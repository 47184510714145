import './SlideView.css';
import { Carousel } from 'react-bootstrap';
import { useState } from 'react';

function SlideView() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect} className="bg-custom">
            <Carousel.Item>
                <img className="w-100" src={process.env.PUBLIC_URL + '/assets/carousel/berandal.png'} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img className="w-100" src={process.env.PUBLIC_URL + '/assets/carousel/matadukun.png'} alt="Third slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img className="w-100" src={process.env.PUBLIC_URL + '/assets/carousel/lostindread.png'} alt="Second slide" />
            </Carousel.Item>
        </Carousel>
    );
}


export default SlideView