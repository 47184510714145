function Separator() {

    return (
        <div className="d-flex">
            <div className="col-12">
                <hr />
            </div>
        </div>
    );

}

export default Separator