import './ContactCard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faXTwitter, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';

function ContactCard() {
    const urlIg = "https://www.instagram.com/aimtomitestudio/";
    const urlTwitter = "https://twitter.com/LivanderGamedev";
    const urlYoutube = "https://www.youtube.com/@LivanderGamedev";
    const urlTiktok = "https://www.tiktok.com/@livandergamedev";

    const redirectIg = () => {
        window.open(urlIg);
    }

    const redirectTwitter = () => {
        window.open(urlTwitter);
    }

    const redirectYoutube = () => {
        window.open(urlYoutube);
    }

    const redirectTiktok = () => {
        window.open(urlTiktok);
    }

    return(
        <div>
            <div className="card-title text-center py-3">
                Join the Aim to Mite Team and Help Shape the Future of Gaming
            </div>
            <div className="card-subtitle text-center" style={{fontStyle: 'normal'}}>
                We believe that our success lies in the strength of our team. That's why we're always looking for talented and passionate individuals to join us on our mission to bring the best gaming experiences to players all over the world
            </div>
            <div className="text-center my-3 card-title" style={{fontStyle: 'normal', fontWeight: 'bolder', color: 'white'}}>
                Contact Us
            </div>
            <div className="col-12 d-flex justify-content-center py-3">
                <div className="col-6 col-md-5">
                    <div className="d-flex justify-content-center icon-container">
                        <FontAwesomeIcon icon={faMailBulk} />
                    </div>
                    <div className="description-sect p-1">
                        <div className="mini-card-subtitle py-2 text-center" style={{fontStyle: 'normal' }}>
                            career@aimtomitestudio.com
                        </div>
                    </div>
                </div> 
            </div>

            <div className="text-center my-3 card-title" style={{fontStyle: 'normal', fontWeight: 'bolder', color: 'white'}}>
                Socials
            </div>
            <div className="col-12 d-flex justify-content-center">
                <div className="col-6 col-md-4 contact-card-container" onClick={redirectIg}>
                    <div className="d-flex justify-content-center icon-container">
                        <FontAwesomeIcon icon={faInstagram} />
                    </div>
                    <div className="description-sect p-1">
                        <div className="mini-card-title py-2 text-center" style={{fontStyle: 'normal'}}>
                            aimtomitestudio
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-4 contact-card-container" onClick={redirectYoutube}>
                    <div className="d-flex justify-content-center icon-container">
                        <FontAwesomeIcon icon={faYoutube} />
                    </div>
                    <div className="description-sect p-1">
                        <div className="mini-card-title py-2 text-center" style={{fontStyle: 'normal'}}>
                            LivanderGameDev
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 d-flex justify-content-center mt-4">
                <div className="col-6 col-md-4 contact-card-container" onClick={redirectTwitter}>
                    <div className="d-flex justify-content-center icon-container">
                        <FontAwesomeIcon icon={faXTwitter} />
                    </div>
                    <div className="description-sect p-1">
                        <div className="mini-card-title py-2 text-center" style={{fontStyle: 'normal'}}>
                            LivanderGameDev
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-4 contact-card-container" onClick={redirectTiktok}>
                    <div className="d-flex justify-content-center icon-container">
                        <FontAwesomeIcon icon={faTiktok} />
                    </div>
                    <div className="description-sect p-1">
                        <div className="mini-card-title py-2 text-center" style={{fontStyle: 'normal'}}>
                            livandergamedev
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )

}

export default ContactCard;