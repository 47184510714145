import './Footer.css';

function Footer() {
    return (
        <div className="footer py-3">
            <div className="col-12">
                <div className="mini-card-subtitle py-3 text-center">
                    <div style={{fontStyle: 'normal'}}>
                        Copyright &copy; Aim To Mite Studio 2023
                    </div>
                    <div style={{fontStyle: 'normal'}}>
                        Livander@aimtomitestudio.com
                    </div>
                </div>                
            </div>
        </div>
    );
}

export default Footer;